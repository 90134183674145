@import "../../styles/colors.sass";

.ngo-agreement {
  border: 1px solid $grey;
  padding: 20px;

  h3 {
    font: 600 22px "Inter";
    margin-bottom: 20px;
  }

  ol {
    list-style: decimal;
    margin: 10px 10px 0 20px;
  }

  li + li {
    margin-top: 10px;
  }
}
