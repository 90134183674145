@import "../../styles/colors"

.cart_total_line
  display: flex
  justify-content: space-between
  align-items: center
  padding: 12px 24px
  border-bottom: 1px solid $grey_f0

.amount
  font-weight: 600

@media screen and (max-width: 480px)
  .cart_total_line
    padding: 12px 0 12px
