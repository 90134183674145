@import "../../styles/colors.sass";

.btn-llink {
  display: inline;
  background: none;
  border: none;
  padding: 0;
  color: $link_color;
  font-family: "Inter";
  font-size: 1em;
  line-height: 100%;

  &:focus {
    outline: 0;
  }

  &:hover {
    color: $button_hover;
    cursor: pointer;
  }
}
