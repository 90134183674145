@import "../../styles/colors.sass";

.grey-panel {
  padding: 24px;
  background: $grey_f7;
  margin: 10px 0;
  font-size: 14px;

  .header {
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 8px;
    text-align: left;
    color: black;
  }
}

.bold {
  font-weight: bold;
}
.tos {
  p {
    color: $grey_63;
    line-height: 21px;
  }
  .data-sharing {
    margin: 20px 0;
    padding: 0 24px;
    h3 {
      padding-bottom: 8px;
    }
  }
  .tos-link {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    i {
      font-size: 20px;
      color: $grey_69;
    }
    .lhs {
      i {
        padding-right: 10px;
        color: $dgrey;
        font-size: 20px;
      }
      span {
        font-size: 16px;
      }
    }
    .rhs {
      position: relative;
      i {
        position: absolute;
        top: -5px;
        left: -10px;
        font-size: 28px;
      }
    }
  }

  .fieldgroup.buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $grey_ea;
    border-bottom: 1px solid $grey_ea;

    .checkbox-field:nth-child(2) {
      margin-top: 15px;
    }

    .checkbox-field {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .checkbox {
        display: flex;
        align-items: center;
        .checkbox-input {
          margin-right: 9px;
        }
      }
    }
  }

  .fieldgroup.buttons.vertical {
    margin-top: 20px;
    flex-direction: column;
  }

  .fieldgroup::after {
    content: none;
  }

  .newsletter-text {
    color: $grey_63;
    font-size: 14px;
    margin-left: 25px;
    margin-top: 5px;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
  }
}

@media screen and (max-width: 479px) {
  .tos {
    .fieldgroup.buttons {
      flex-direction: column;
      height: 120px;

      .checkbox-input {
        margin-right: 15px;
        transform: scale(1.2)
      }
    }
  }
}
