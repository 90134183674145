@import "../../styles/colors"

@mixin grey_border_bottom
  border-bottom: 1px solid $grey_f0

.term_line
  @include grey_border_bottom
  display: flex
  align-items: center
  justify-content: space-between
  padding: 18px 0
  margin: 0 20px

  &.subscription_term_line
    padding: 30px 4px

.term_right
  display: flex

.mailbox
  @include grey_border_bottom
  padding-bottom: 12px
  margin: 0 20px
  > p
    padding-bottom: 0
  p
    border: none
    margin: 0
.mailbox_item,.instant_savings
  display: flex
  justify-content: space-between
  align-items: center

.mailbox_item
  padding: 25px 0 0 12px

  &:first-of-type
    padding-top: 22px

.instant_savings
  padding: 5px 0 0 12px

.instant_savings_label
  color: $grey_69

.cost
  padding-left: 8px

.amount, .instant_savings_amount
  display: inline-block
  width: 92px
  text-align: right
  font-weight: 600

.instant_savings_amount
  color: $bundle_orange

.control
  display: inline-block

.label
  p
    padding: 0
    &:last-child
      color: $grey_69

.note
  font-size: 12px
  color: $grey_69
  .star
    padding-right: 5px
    margin-top: 2px
    margin-left: 0
  p
    margin: 15px 30px 0 0
    padding: 0
    border: none

.compare_link
  text-transform: uppercase
  font-weight: 600
  font-size: 12px
  line-height: 15px
  padding-left: 5px

.bundle_offer_label
  background-color: $bundle_orange
  color: #ffffff
  display: inline-block
  padding: 2px 6px
  margin-left: 10px
  text-transform: uppercase

.text_orange
  color: $bundle_orange

.strike_out_price
  color: #69747E
  font-size: 12px
  font-weight: 600
  line-height: 16px
  text-decoration: line-through

@media screen and (min-width: 481px)
  .compare_link
    display: none

@media screen and (max-width: 480px)
  .term_label
    align-self: flex-start

  .term_line
    flex-direction: column
    padding: 16px 0

  .term_right
    align-self: flex-end
    padding-top: 10px

  .cost, .tagline
    display: none

  .quantity
    display: flex
