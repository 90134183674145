@import "../../styles/colors.sass";

.modal-header {
  padding-bottom: 15px;
  border-bottom: 1px solid $grey;
  lost-utility: clearfix;

  .closeButton {
    font-size: 18px;
  }

  h4 {
    font-weight: 600;
  }
}

.modal-body {
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-footer {
  text-align: right;

  .delete-button {
    float: left;
    line-height: 40px;
  }

  button + button {
    margin-left: 10px;
  }
}

.checkout-notice {
  background: $white;
  border: 10px solid black;
  border-radius: 0;
  outline: none;
  padding: 1em;
  margin-right: auto;
  margin-left: auto;

  position: absolute;
  top: 25%;
  right: 0;
  bottom: auto;
  left: 0;
  width: calc(80% + 60px);
  max-width: 1000px;
  height: auto;

  .modal-close {
    font-size: 1em;
  }

  .modal-content {
    .modal-title {
      margin-bottom: 1em;
    }

    p {
      white-space: pre-line;
    }
  }
}

@media screen and (min-width: 768px) {
  .checkout-notice {
    width: calc(40% + 60px);
    max-width: 820px;
  }
}

@media screen and (min-width: 1024px) {
  .checkout-notice {
    padding: 2em;
  }
}
