.xfade-leave {
  opacity: 1;
}

.xfade-leave.xfade-leave-active {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.xfade-enter {
  opacity: 0;
}

.xfade-enter.xfade-enter-active {
  opacity: 1;
  transition: opacity 0.2s ease-in 0.3s;
}

.xfade-height {
  transition: height 0.3s ease-in-out;
}
