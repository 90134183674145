@import "../../styles/colors.sass";

.scrolling-agreement {
  height: 300px;
  border: 1px solid $grey;
  padding: 20px;
  overflow: auto;
}

.legal-type-warning, .uk-warning {
  margin-top: 10px;
  padding: 20px;
  border: 2px solid $lgreen;

  p {
    margin-top: 20px;
  }
  p:first-child {
    margin-top: 0;
  }
}
