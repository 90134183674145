.number_control
  color: #3AA48B
  &:hover
    color: #1D8975
    cursor: pointer
  &.disabled
    color: #D9DCDF
    cursor: not-allowed

.display_text
  display: inline-block
  font-size: 14px
  font-weight: 600
  text-transform: uppercase
  width: 32px
  text-align: center

.display_text_wide
  display: inline-block
  font-size: 14px
  width: 80px
  text-align: center
