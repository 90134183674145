@import "../../styles/colors.sass";
@import "./buttons.scss";
@import "./mixins.scss";
@import "./modal.scss";
@import "./tos.scss";

@lost gutter 20px;

h1 {
  line-height: 100%;
}

section.checkout main {
  padding: 20px;
}

section.checkout.active[data-name='account'] {
  main {
    padding: 0;
  }

  form {
    padding: 40px 60px;
  }

  .magic-signin-link {
    border: none;
    border-top: 1px solid $grey_e7;

  }
}

section.checkout {
  width: 100%;
  padding: 0;
  border: 1px solid $grey_ea;
  margin: 0;
  margin-bottom: 20px;
  text-align: left;
  color: $black;
  position: relative;

  main {
    display: block;
  }
}

p.disclaimer {
  border-top: 1px solid $grey_ea;
  padding-top: 10px;
  margin-top: 10px;
}

h2.section-title {
  display: flex;
  align-items: center;
  margin: 0;
  line-height: 100%;
  height: 45px;
  background: $grey_ea;
  padding: 0 20px;

  @include caps;
}

.btn-edit {
  position: absolute;
  top: 0;
  right: 0;
  height: 45px;
  line-height: 45px;
  min-width: 45px;
  text-align: right;
  margin-right: 10px;

  @include caps;

  i {
    line-height: 0;
  }
}

dt {
  @include caps;
}

dd {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.confirm_total {
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
}

.minicart {
  position: relative;
}

.minicart-item {
  padding: 19px 0 16px;
}

div.account-form {
  main {
    padding: 0 20px 10px;
  }
  .response {
    font-size: 12px;
    font-weight: bold;
    color: $red;
  }
  .create-account main {
    padding: 10px 0;
  }
  .data-sharing {
    padding: 10px 20px;
    h3 {
      font-size: 18px;
      padding-bottom: 10px;
    }
    p {
      font-size: 14px;
    }
  }
}

.signin-note {
  font: 500 1em "Inter";
}

.is-error {
  color: $invalid_red;
}

.premium-star {
  margin-left: 6px;
}

.premium-star.premium-aftermarket {
  color: $yellow;
}

.premium-star.premium-registry {
  color: $blue;
}

.btn-paypal {
  display: inline;
  background: none;
  border: none;
  padding: 0;
}

#paypal-button:hover .paypal-button-content {
  background: #0DA9EB; /* 5% lighter than original color */
}

#paypal-button:disabled .paypal-button-content {
  opacity: 0.7;
  background: #009cde; /* original color */
}

.billing-summary-line {
  text-align: right;
  line-height: 1.6;
}

.billing-summary-line.account-balance {
  font-weight: bold;
}

.spinner-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  line-height: 1;

  p {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    color: $black;
    font: 400 18px "Inter";
  }
}

.ReactModalPortal > * {
  opacity: 0;
}

.ReactModal__Overlay {
  transition: opacity 400ms ease-in-out;
  background: rgba(0, 0, 0, 0.15);
  &--after-open {
    opacity: 1;
  }
  &--before-close {
    opacity: 0;
  }
}

.bold {
  font-weight: 600;
}

.float-right {
  float: right
}

.text-domain-bundle {
  color: $bundle_orange;
}

@media screen and (max-width: 768px) {
  section.main {
    padding-top: 20px;

    h1 {
      margin-bottom: 10px;
    }
  }

  .results .left {
    width: 100%;

    main {
      margin-top: 0;
    }
  }

  .results .right {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .results .left {

    section.checkout {
      border: none;
      margin-bottom: 0;
    }

    section.checkout[data-name="confirm"] {
      button.submit {
        margin: 0 auto 18px;
        display: block;
      }

      .confirm_total {
        display: block;
        text-align: center;
      }
    }
  }

  .left {
    .editor {
      .account-form {
        main {
          padding: 0;
        }
      }
    }
  }

  .tos {
    .fieldgroup.buttons {
      flex-direction: column;
      height: 120px;

      .checkbox-input {
        margin-right: 15px;
        transform: scale(1.2)
      }
    }
  }
}
