.buttons
  display: flex
  justify-content: flex-end
  align-items: center
  padding-top: 15px
  border-top: 1px solid #D9DBDD

.recovery_code_button
  margin-right: auto

.resend_button
  margin-right: 24px

.verification_holder
  padding-top: 0
  padding-bottom: 16px

@media (max-width: 480px)
  .buttons
    flex-direction: column-reverse
    button
      width: 100%
      margin-bottom: 8px
  .recovery_code_button,.resend_button
    margin-right: 0
    padding: 8px 0 !important
