@import "../../styles/colors.sass";

button.btn-link {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

section.main .signin {
  margin-top: 20px;

  .header {
    text-align: center;
    h1 {
      font-size: 42px;
      font-weight: 600;
      margin: 40px 0;
    }

    p {
      padding-bottom: 15px;
    }
  }

  &.signin-verification {
    form {
      padding: 0;
      border: none;
    }

    .fieldgroup.buttons {
      padding-top: 16px;
    }
  }

  &.two-factor-email {
    form {
      max-width: 616px;
      border: none;
      padding: 0
    }
  }

  .email-verification {
    max-width: 616px;
    margin: 0 auto;
  }

  form {
    max-width: 580px;
    width: 100%;
    margin: 0 auto;
    border: 1px solid $grey_e7;
    padding: 80px 40px;
  }

  form.bad {
    padding-top: 17px;
  }

  form.hasError {
    border-top: none;
    padding-top: 0;
  }

  .flat-checkbox-label {
    text-transform: uppercase;
    line-height: 20px;
    font-size: 12px;
  }

  .field {
    margin-top: 0;
  }

  .row {
    margin-top: 24px;
    &:first-child {
      margin-top: 0;
    }
  }

  .email-confirmation {
    max-width: 616px;
    margin: 0 auto;
    .settings-email-form {
      max-width: 616px;
      border: none;
      padding-top: 0;
      .attribute-list {
        padding: 0
      }
    }
    .modal-body {
      margin-top: 0;
    }
    .modal-footer {
      padding-top: 10px;
    }
  }
}

.account_signin_form {
  .signin-controls {
    margin-top: 40px;
  }
}

.magic-signin-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  border: 1px solid $grey_e7;
  border-top: none;

  button {
    font-size: 14px;
  }

  span {
    margin-left: 8px;
  }
}

.user-select {
  padding: 80px 40px;
  border: 1px solid #E7E8EB;
  max-width: 580px;
  width: 100%;
  margin: auto;
  h2 {
    text-align: center;
    color: #191D21;
    margin: 0;
    font-weight: 600;
    font-size: 16px;
  }
  .user-rows {
    margin: 24px 0;
  }
  .user-row {
    height: 48px;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E7E8EB;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    i {
      color: #6C7074;
    }
  }
  .notice {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6C7074;
    padding: 0 50px;
  }
}

.signin-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  .buttons {
    margin-left: auto;
  }
}

.skip-button {
  margin-right: 32px;
}


@media screen and (max-width: 479px) {
  #app .signin {
    padding-left: 20px;
    padding-right: 20px;

    h1 {
      padding: 0;
    }

    .row {
      margin-top: 20px;
    }

    .field {
      margin-top: 20px;
    }

    .field:first-child {
      margin-top: 0;
    }
  }
}
