@import "../../styles/colors"

.total
  display: flex
  justify-content: space-between
  align-items: flex-start
  padding: 12px 24px

.total_amount
  font-weight: 600
  font-size: 32px
  text-align: right

.submit
  padding: 40px 24px 0
  display: flex
  flex-direction: column
  align-items: flex-end

.note
  margin-top: 12px
  font-size: 12px
  color: $grey_69

.remove_promo
  display: inline-block
  vertical-align: top
  margin-left: 6px

.promo
  text-transform: uppercase
  font-weight: 600
  font-size: 12px

.empty_cart
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  border: 1px solid $grey_f0
  padding: 80px 0
  h2
    font-size: 20px
    margin: 0 0 24px

.error_container
  display: flex
  width: 100%

@media screen and (max-width: 480px)
  .cart_totals
    padding: 0 16px

  .submit
    padding: 40px 0 0

  .total
    flex-direction: column
    align-items: flex-start
    padding: 12px 0 0

  .total_amount
    align-self: flex-end

  .promo_code
    padding: 12px 0
